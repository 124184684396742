iframe#webpack-dev-server-client-overlay{display:none!important}

body {
  font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue",
    "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.modal {
  position: fixed;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 50vh;
  max-width: 50vh;
  width: 800px;
  z-index: 1040;
  top: 30%;
  left: 30%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.update-reservation-modal {
  position: fixed;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 50vh;
  max-width: 500vh;
  width: 700px;
  z-index: 1040;
  top: 30%;
  left: 30%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.modal-populate-reservations {
  position: fixed;
  overflow-x: auto;
  overflow-y: auto;
  max-height: 50vh;
  max-width: 500vh;
  width: 900px;
  z-index: 1040;
  top: 30%;
  left: 30%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.synced {
  width: 180%;
  margin-bottom: 20px;
  border-collapse: collapse;
}

.synced th, .synced td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.synced th {
  background-color: #f2f2f2;
  color: black;
}


.scrollable-content {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: auto;
}

.backdrop {
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
}

.modal-title {
  font-weight: 500;
  font-size: 1.25rem;
}

.modal-header {
  border-bottom: 1px solid #e9ecef;
  display: flex;
  justify-content: space-between;
}

.modal-desc,
.modal-header,
.modal-footer {
  padding: 12px;

}

.close-button {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  border: none;
  background-color: white;
  opacity: 0.7;
  cursor: pointer;
}
button {
  cursor: pointer;
  font-size: 1rem;
  padding: 10px 12px;
  border-radius: 5px;
  border: none;
}

.modal-footer {
  border-top: 1px solid #e9ecef;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.secondary-button {
  color: #fff;
  background-color: #6c757d;
}
.primary-button {
  color: #fff;
  background-color: #007bff;
}

.modal-example {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
