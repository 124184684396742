/*
 * Copyright (C) 2025 Mahon Media.
 *
 * This source code is protected under international copyright law. All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
.cron-job-toggle {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.cron-job-toggle__label {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.cron-job-toggle__button {
  padding: 20px 40px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 24px;
  background-color: transparent;
  color: inherit;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cron-job-toggle__button.active {
  background-color: #4caf50;
  color: white;
}

.cron-job-toggle__button.inactive {
  background-color: #f44336;
  color: white;
}

.cron-job-toggle__button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.cron-job-toggle__button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}
