/*
 * Copyright (C) 2025 Mahon Media.
 *
 * This source code is protected under international copyright law. All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
.rate-code-manager {
  font-family: Arial, sans-serif;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  font-size: 14px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
  font-size: 14px;
}

button {
  padding: 4px 8px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}

button:hover {
  opacity: 0.8;
}

button:hover {
  background-color: #ff4d4d;
}

button:focus {
  outline: none;
}

input {
  padding: 5px;
  font-size: 14px;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button + button {
  margin-left: 10px;
  background-color: #2ecc71;
}

button + button:hover {
  background-color: #27ae60;
}

button {
  background-color: #3498db;
  color: white;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 5px;
  margin-top: 10px;
}

button:hover {
  background-color: #2980b9;
}
