/*
 * Copyright (C) 2025 Mahon Media.
 *
 * This source code is protected under international copyright law. All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */
* {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f7fc;
  color: #333;
  margin: 0;
  padding: 0;
}

.dashboard {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.dashboard__title {
  font-size: 30px;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 30px;
  text-align: center;
}

.dashboard__container {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.dashboard__container div {
  background: #f9fafb;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
  max-width: 400px;
}

@media (min-width: 768px) {
  .dashboard__container {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
  }

  .dashboard__container div {
    max-width: 500px;
  }
}

input,
select,
button {
  font-size: 16px;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

input[type="text"] {
  width: 100%;
}

select {
  width: 120px;
}

button {
  background-color: #3498db;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #2980b9;
}

.logout-button {
  margin-top: 20px;
  background-color: #e74c3c;
  color: #fff;
  padding: 12px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s;
}
